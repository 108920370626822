import { post } from '@helpers/fetch-wrapper'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import useSWRImmutable from 'swr/immutable'

import { appActions } from '@features/app/reducers'
import CoinsBanner from '@features/coins/components/coins-banner'
import FreeCoinsBanner from '@features/coins/components/free-coins-banner/FreeCoinsBanner'
import FreeCoinsModal from '@features/coins/components/free-coins-modal/FreeCoinsModal'
import PriceListDrawer from '@features/coins/components/price-list-drawer/PriceListDrawer'
import TransactionHistoryDrawer from '@features/coins/components/transaction-history-drawer'
import KycVerificationBanner from '@features/kyc/components/KycVerificationBanner'
import { GetEmployerProfileAPIResponseData } from '@features/kyc/types'

import Layout from '@components/ui/layout'

import { useAppDispatch, useAppSelector } from '@hooks/redux'

import { PATHS } from '@constants/paths'
import { EMPLOYERS_PROFILE_URL, INITIALIZE_URL } from '@constants/url'

import ChangeJobStatusQuestionModal from '../components/change-job-status-question-modal'
import ChargeRepostJobModal from '../components/charge-repost-job-modal'
import JobListing from '../components/job-listing'
import JobListingBoostNoticeModal from '../components/job-listing-boost-notice-modal'
import JobListingCandidateSearchNoticeModal from '../components/job-listing-candidate-search-notice-modal'
import JobListingDetailDrawer from '../components/job-listing-detail-drawer'
import JobListingKycFormSentModal from '../components/job-listing-kyc-form-sent-modal'
import JobListingKycInReviewModal from '../components/job-listing-kyc-in-review-modal'
import JobListingKycNoticeModal from '../components/job-listing-kyc-notice-modal'
import JobListingOnboardModal from '../components/job-listing-onboard-modal'
import JobListingSearchInput from '../components/job-listing-search-input'
import JobListingSort from '../components/job-listing-sort'
import JobListingStatusFilter from '../components/job-listing-status-filter'
import JobListingTitle from '../components/job-listing-title'
import RepostJobModal from '../components/repost-job-modal'
import TopupCoinsJobModal from '../components/topup-coins-job-modal'
import UnsufficientBalanceModal from '../components/unsufficient-balance-modal'
import SuspendedHero from './hero/suspended-hero'

const JobsScreenWrapper = ({ children }: { children?: React.ReactNode }) => {
  const { data: employerProfileData } =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(EMPLOYERS_PROFILE_URL)
  const accountSuspended = employerProfileData?.kycStatus === 'Suspended'

  if (accountSuspended) {
    return (
      <div className="h-full bg-white p-[24px]">
        <div className="container mx-auto">
          <SuspendedHero />
        </div>
      </div>
    )
  }

  return children
}

const JobsScreen = () => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const { isFreeCoinsBannerOpen, isFreeCoinsModalOpen } = useAppSelector(
    (state) => state.appReducer
  )

  const { data: initializeData } = useSWRImmutable<{
    coins: {
      showWelcomeCoinPopup: boolean
      welcomeCoinTransactionId: string
    }
  }>(INITIALIZE_URL, async (url: string) => {
    try {
      const response = await post(url)
      return response.data
    } catch (e) {
      return undefined
    }
  })

  useEffect(() => {
    if (initializeData) {
      dispatch(
        appActions.setIsFreeCoinsBannerOpen(
          initializeData?.coins.showWelcomeCoinPopup
        )
      )

      dispatch(
        appActions.setIsFreeCoinsModalOpen(
          initializeData?.coins.showWelcomeCoinPopup
        )
      )
    }
  }, [initializeData])

  return (
    <Layout mainClassname="bg-white">
      <JobsScreenWrapper>
        <CoinsBanner />
        <div className="relative flex flex-1 flex-col bg-white p-[40px]">
          {isFreeCoinsBannerOpen && <FreeCoinsBanner />}
          <FreeCoinsModal
            isOpen={Boolean(
              initializeData?.coins.showWelcomeCoinPopup && isFreeCoinsModalOpen
            )}
            transactionId={
              initializeData?.coins?.welcomeCoinTransactionId as string
            }
          />
          <JobListingTitle />
          <div className="flex-1 bg-white py-6">
            <div className="mx-auto">
              <KycVerificationBanner />
              <div className="mb-4 gap-[8px] grid lg:grid-cols-2 items-center justify-between w-full">
                <JobListingStatusFilter />
                <div className="flex items-center flex-wrap gap-[8px] lg:justify-end">
                  <JobListingSearchInput />
                  <JobListingSort />
                </div>
              </div>
              <JobListing />
            </div>
          </div>
          <button
            type="button"
            className="fixed bottom-6 right-6 flex h-12 w-12 items-center justify-center rounded-full bg-primary-blue shadow-lg shadow-gray-400 lg:hidden"
            onClick={() => router.push(PATHS.CREATE_JOB)}
          >
            <Image
              alt="icon"
              src="/assets/icons/ic_add.svg"
              width={20}
              height={20}
              layout="fixed"
            />
          </button>
        </div>
        <ChangeJobStatusQuestionModal />
        <RepostJobModal />
        <JobListingOnboardModal />
        <JobListingKycNoticeModal />
        <JobListingKycInReviewModal />
        <JobListingKycFormSentModal />
        <JobListingCandidateSearchNoticeModal />
        <JobListingBoostNoticeModal />
        <JobListingDetailDrawer />
        <UnsufficientBalanceModal />
        <TopupCoinsJobModal />
        <TransactionHistoryDrawer />
        <PriceListDrawer />
        <ChargeRepostJobModal />
      </JobsScreenWrapper>
    </Layout>
  )
}

export default JobsScreen
