import Image from 'next/image'
import React from 'react'

import searchFilterStyles from '@features/applications/components/candidate-list-search-filter/CandidateListSearchFilter.module.css'
import { useJobsActions } from '@features/jobs/job-detail/reducers/JobReducer'

import TextInput from '@components/form/text-input'

import { JOB_SEARCH_SEARCHED_FOR_CANDIDATE } from '@constants/event-tracking'

import trackEvents from '@utils/track-events'

import CloseIcon from '@common/icons/CloseIcon'

const JobListingSearchInput = () => {
  const searchInputRef = React.useRef<HTMLInputElement>(null)
  const timeoutId = React.useRef<NodeJS.Timeout>()

  const jobsActions = useJobsActions()

  const onInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
      }

      timeoutId.current = setTimeout(() => {
        trackEvents(JOB_SEARCH_SEARCHED_FOR_CANDIDATE)
        jobsActions.setJobListingQueries({
          searchText: e.target.value || undefined,
        })
      }, 500)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <div className="max-w-[400px]">
      <TextInput
        id="job_listing_search_input"
        ref={searchInputRef}
        startIcon={
          <Image
            alt="icon"
            src="/assets/icons/ic_search.svg"
            width={16}
            height={16}
          />
        }
        type="text"
        onChange={onInputChange}
        placeholder="Cari loker..."
        containerClassName={`items-center ${searchFilterStyles.searchContainer}`}
        endIcon={
          <CloseIcon
            width={16}
            height={16}
            className="cursor-pointer align-middle"
            onClick={() => {
              if (!searchInputRef.current) return
              searchInputRef.current.value = ''
              jobsActions.setJobListingQueries({
                searchText: undefined,
              })
            }}
          />
        }
      />
    </div>
  )
}

export default JobListingSearchInput
