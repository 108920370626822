import Image from 'next/image'
import { useRouter } from 'next/router'
import React from 'react'

import Button from '@components/button'

import { Media } from '@custom-types/job-post'

type Props = {
  medias: Pick<Media, 'mimeType' | 'linkURL' | 'link'>[]
  jobId: string
}
const JobCardMedia = ({ medias, jobId }: Props) => {
  const router = useRouter()
  return medias && medias.length > 0 ? (
    <div className="flex overflow-x-auto overflow-y-hidden whitespace-nowrap scrollbar-thin scrollbar-track-blue-100 scrollbar-thumb-blue-500 hover:scrollbar-thumb-blue-700">
      {medias.map((data) => {
        const mediaType = data?.mimeType.split('/')
        switch (mediaType[0]) {
          case 'video':
            return (
              <a
                target="_black"
                href={data?.linkURL}
                onClick={(e) => e.stopPropagation()}
                className="cursor-pointer px-2"
              >
                <div className="w-[80px] h-[80px]">
                  {' '}
                  <video className="h-[80px] w-[80px]">
                    <source src={data?.linkURL} />
                    <track kind="captions" />
                  </video>
                </div>
              </a>
            )
          case 'image':
            return (
              <a
                href={data?.linkURL}
                target="_blank"
                className="px-2"
                onClick={(e) => e.stopPropagation()}
                rel="noreferrer"
              >
                <div className="w-[80px] h-[80px]">
                  {' '}
                  <Image
                    src={data?.linkURL}
                    alt="job media"
                    height={80}
                    width={80}
                  />
                </div>
              </a>
            )
          default:
            return ''
        }
      })}{' '}
    </div>
  ) : (
    <Button
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        router.push(`/jobs/edit?jlid=${jobId}&page=supporting-visual-media`)
      }}
      type="button"
      appearance="text"
      fullWidth
      className="flex border-[1px] text-left hover:border-primary-blue hover:bg-white rounded-md py-7 px-3 items-center justify-between"
    >
      <div className="mr-1">
        <p className="font-semibold text-primary-black text-sm">
          Belum ada foto atau video
        </p>
        <p className="text-sm text-primary-gray">
          Tambahkan agar lebih menarik
        </p>
      </div>

      <div className="text-primary-blue font-semibold text-sm w-fit p-0">
        Upload Media
      </div>
    </Button>
  )
}

export default JobCardMedia
